import { useAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { Planogram } from "src/components";
import { useModals } from "src/components/Modals";
import { PlanogramHovererDetails } from "src/components/PlanogramExtensions/PlanogramHovererDetails/PlanogramHovererDetails";
import { Button, Color, Flex, Input, Modal, Text } from "src/elements";
import { Metrics } from "src/pages/MerchflowTemplates/components/Metrics";
import { routeToUrl } from "src/utils/axios";
import { useNavigation } from "src/utils/navigation";
import { pages } from "src/utils/pages";
import { storePlanogramReviewAtom } from "./store/atoms";
import { omniAtom } from "src/utils/atoms";
import { adapterPogNonCircularToPog } from "@CommonAdapters/pog";
import { emptyContainersAtom } from "src/pages/merchandise/store/atoms";
import { useApi } from "src/api";
import { useStores_storeCodeData } from "src/pages/Merchflows_merchflowId_Stores_storeCode/hooks";
import { useInlineLoaders } from "src/components/InlineLoader";
import { RoutePutMerchflows_MerchflowId_StoresApprovals } from "@CommonApi/merchflows/_merchflowId_/stores/approvals";
import { usePowerBiReportModal } from "../PowerBiReportModal/hooks";
import { useAsyncState } from "src/hooks/useAsyncState";
import { ResponseMerchFlowApplyFilters } from "@CommonApi/merchandise";
import { RoutePostMerchflowCreatePlanogramTemplate } from "@CommonApi/merchflow/createPlanogramTemplate";
import { emptyPogNonCircular } from "src/pages/MerchflowTemplateBasePogs/store/atom";
import { OpenStorePlanogramReviewModal } from "./store/hooks";

export const StorePlanogramReviewModal = () => {
  const [data, setData] = useAtom(storePlanogramReviewAtom);

  useEffect(() => {
    return () => {
      setData(null);
    };
  }, []);

  return data ? (
    <StorePlanogramReviewModalInner
      merchflowId={data.merchflowId}
      storePlanograms={data.storePlanograms}
      planogramIndex={data.planogramIndex}
    />
  ) : null;
};

const StorePlanogramReviewModalInner = ({
  merchflowId,
  storePlanograms,
  planogramIndex: _planogramIndex,
}: OpenStorePlanogramReviewModal) => {
  const {
    putMerchflowApprovalsApi,
    getMerchflowReviewApi,
    postMerchflowCreatePlanogramTemplateApi,
  } = useApi();
  const { navigate } = useNavigation();
  const { closeModal: _closeModal, openConfirmModal } = useModals();
  const { isInlineLoading } = useInlineLoaders();
  const { openOneStoreReport } = usePowerBiReportModal();
  const { page, loadStorePogs, storeCode } = useStores_storeCodeData();

  const [data, setData] = useAtom(storePlanogramReviewAtom);
  const [planogramIndex, setPlanogramIndex] = useState(_planogramIndex ?? 0);
  const [filter, setFilter] =
    useAsyncState<ResponseMerchFlowApplyFilters>(null);

  const [isCreatingTemplate, setIsCreatingTemplate] = useState(false);
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [templateName, setTemplateName] = useState("");
  const isCreateTemplateLoading = isInlineLoading(
    RoutePostMerchflowCreatePlanogramTemplate,
  );

  useEffect(() => {
    setFilter(getMerchflowReviewApi(merchflowId));
  }, []);

  const storePlanogramAtom = useMemo(() => {
    const storeView = data?.storePlanograms[planogramIndex];
    const storePog =
      storeView && storeView.storePog && "storePogData" in storeView.storePog
        ? storeView.storePog.storePogData
        : emptyPogNonCircular;
    return omniAtom({
      key: "modals/storePlanogramReviewer/storePlanogram",
      value: adapterPogNonCircularToPog(storePog),
    });
  }, [planogramIndex]);

  const planogramView = useMemo(() => {
    return storePlanograms[planogramIndex];
  }, [planogramIndex, data]);
  const isApproved = planogramView.storePog?.isApproved || false;

  const modalTitle = `${
    planogramView.storePog?.storePogId &&
    `Store Planogram ID: ${planogramView.storePog?.storePogId}`
  } ${
    planogramView.basePog?.basePogId
      ? `, Base Planogram ID: ${planogramView.basePog.basePogId}`
      : ""
  }  ${
    planogramView.templateId ? `, Template ID: ${planogramView.templateId}` : ""
  }`;

  const toggleStorePlanogramApprove = async () => {
    if (!planogramView.storePog) return;

    const response = await putMerchflowApprovalsApi(merchflowId, {
      unapproved: isApproved
        ? [{ store_pog_id: planogramView.storePog.storePogId }]
        : [],
      approved: isApproved
        ? []
        : [{ store_pog_id: planogramView.storePog.storePogId }],
    });

    // Manually override data to update approval status.
    if (response) {
      setData((data) => {
        return data
          ? {
              ...data,
              storePlanograms: storePlanograms.map((storePlanogram, index) => ({
                ...storePlanogram,
                storePog: storePlanogram.storePog
                  ? {
                      ...storePlanogram.storePog,
                      isApproved:
                        index === planogramIndex
                          ? !isApproved
                          : storePlanogram.storePog.isApproved,
                    }
                  : undefined,
              })),
            }
          : null;
      });
      loadStorePogs(page);
    }
  };

  const closeModal = () => {
    _closeModal("StorePlanogramReviewModal");
  };

  const navigateToBasePogPage = () => {
    if (!planogramView.basePog) return;

    navigate(
      routeToUrl(pages.merchflows_merchflowId_BasePog_basePogId_, {
        merchflowId,
        basePogId: planogramView.basePog.basePogId,
      }),
    );
    closeModal();
  };

  const openCreateTemplatePlanogram = () => {
    if (!filter || !planogramView.basePog || !planogramView.storePog) return;

    setIsCreatingTemplate(true);
    setCategory(
      filter.filter_config.stores.find((store) => store.store === storeCode)
        ?.retailer_category_code || "",
    );
    setSubCategory(
      filter.filter_config.stores.find((store) => store.store === storeCode)
        ?.category_code || "",
    );
    setTemplateName(
      `STORE ID: ${storeCode} - STORE POG ID: ${planogramView.storePog.storePogId}`,
    );
  };

  const navigateToEditStorePog = () => {
    if (!planogramView.storePog) return;

    navigate(
      routeToUrl(
        pages.merchflows_merchflowId_Stores_storeCode_Pogs_storePogId_,
        {
          merchflowId,
          storeCode,
          storePogId: planogramView.storePog.storePogId,
        },
      ),
    );
    closeModal();
  };

  const navigateToTemplatePage = () => {
    if (!planogramView.templateId) return;

    navigate(
      routeToUrl(pages.merchflowTemplateBasePogs, {
        merchflowId,
        templateId: planogramView.templateId,
      }),
    );
    closeModal();
  };

  const viewReport = async () => {
    if (!filter || !planogramView.basePog) return;

    openOneStoreReport([
      {
        table: "gen_id_table",
        column: "filter_config_id",
        value: [filter.id],
      },
      {
        table: "base_pog_id",
        column: "base_pog_id",
        value: [planogramView.basePog.basePogId],
      },
      {
        table: "store",
        column: "store_code",
        value: [storeCode],
      },
    ]);
  };

  const onPrev = () => {
    if (planogramIndex > 0) {
      setPlanogramIndex(planogramIndex - 1);
    }
  };

  const onNext = () => {
    if (planogramIndex < storePlanograms.length - 1) {
      setPlanogramIndex(planogramIndex + 1);
    }
  };

  const saveTemplatePlanogram = async () => {
    if (!planogramView.storePog) return;
    const success = await postMerchflowCreatePlanogramTemplateApi({
      store_pog_id: planogramView.storePog.storePogId,
      category: category,
      subcategory: subCategory,
      template_name: templateName,
    });

    if (!success) return;
    openConfirmModal({
      title: "Template Planogram saved successfully!",
      description: (
        <Text textAlign="center" variant="small2" color={Color.textSecondary}>
          To create a new merchflow using this template, go to Homepage and
          click <b>"Create from Template"</b>
        </Text>
      ),
      buttons: [
        {
          label: "OK",
          onClick: () => {
            setIsCreatingTemplate(false);
          },
        },
      ],
      icon: { name: "toastSuccess" },
    });
  };

  return (
    <Modal
      name="StorePlanogramReviewModal"
      title={modalTitle}
      width="90vw"
      height="95vh"
    >
      <Flex
        height="100%"
        padding="10px"
        width="100%"
        justify="between"
        gap="10px"
      >
        <Flex direction="column" minWidth="0">
          <Planogram
            pogAtom={storePlanogramAtom}
            containersAtom={emptyContainersAtom}
            isPog
            isEditable
          />

          <PlanogramHovererDetails />
        </Flex>

        <Flex width="210px" column justify="between" height="100%" gap="10px">
          <Flex width="100%">
            <Button
              width="100%"
              dropdown={[
                {
                  label: isApproved ? "Unapprove" : "Approve",
                  onClick: toggleStorePlanogramApprove,
                },
                {
                  label: "Edit Template Planogram",
                  onClick: navigateToBasePogPage,
                },
                {
                  label: "Create Template Planogram",
                  onClick: openCreateTemplatePlanogram,
                  isLoading: !filter,
                },
                {
                  label: "Edit Store Planogram",
                  onClick: navigateToEditStorePog,
                },
                {
                  label: "View All Template versions",
                  onClick: navigateToTemplatePage,
                },
                {
                  label: "View Report",
                  onClick: viewReport,
                  isLoading: !filter,
                },
              ]}
              variant="inverted"
              isLoading={isInlineLoading(
                RoutePutMerchflows_MerchflowId_StoresApprovals,
              )}
            >
              Store Options
            </Button>
          </Flex>

          <Metrics metrics={planogramView.storePog?.storePogData.metrics} />

          <Flex justify="space-between">
            <Button
              onClick={onPrev}
              variant="borderless"
              isDisabled={planogramIndex === 0}
              iconLeft={{ name: "arrowLeft" }}
            >
              Prev
            </Button>

            <Button
              onClick={onNext}
              variant="borderless"
              isDisabled={planogramIndex === storePlanograms.length - 1}
              iconRight={{ name: "arrowRight" }}
            >
              Next
            </Button>
          </Flex>

          {isCreatingTemplate && (
            <Flex
              column
              gap="15px"
              background={Color.lightGray}
              borderColor={Color.lightGrayHover}
              borderRadius="3px"
              padding="5px"
              margin="20px 0 0 0"
            >
              <Text variant="body1">Create Template Planogram</Text>

              <Input
                title="Category"
                value={category}
                setValue={setCategory}
                isDisabled
              />

              <Input
                title="Sub-Category"
                value={subCategory}
                setValue={setSubCategory}
                isDisabled
              />
              <Input
                title="Template Name"
                value={templateName}
                setValue={setTemplateName}
                isDisabled={isCreateTemplateLoading}
              />

              <Flex justify="between">
                <Button
                  color="red"
                  variant="inverted"
                  onClick={() => setIsCreatingTemplate(false)}
                  isDisabled={isCreateTemplateLoading}
                >
                  Cancel
                </Button>

                <Button
                  color="greenSmoke"
                  onClick={saveTemplatePlanogram}
                  isLoading={isCreateTemplateLoading}
                >
                  Save
                </Button>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Modal>
  );
};
