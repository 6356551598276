import { Page, PageStatus, Planogram } from "src/components";
import { MerchflowHeader } from "../../components/merchflows/MerchflowHeader/MerchflowHeader";
import { useParams } from "react-router-dom";
import { MerchflowStepsNavigator } from "../../components/merchflows/MerchflowStepsNavigator/MerchflowStepsNavigator";

import { useAsyncState } from "src/hooks/useAsyncState";
import { useEffect } from "react";
import { useApi } from "src/api";
import {
  ResponseGetMerchflows_merchflowId_S2_s2SolutionId_,
  RouteGetMerchflows_merchflowId_S2_s2SolutionId_,
} from "@CommonApi/merchflows/_merchflowId_/s2/_s2SolutionId_";
import { useInlineLoaders } from "src/components/InlineLoader";
import { PrimitiveAtom, useAtom } from "jotai";
import {
  adapterPlanogramNonCircularToPog,
  adapterPogToPogNonCircular,
} from "@CommonAdapters/pog";
import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";
import {
  merchflows_merchflowId_S2Solution_s2SolutionId_PogAtom,
  merchflows_merchflowId_S2Solution_s2SolutionId_ContainersAtom,
} from "./store/atoms";
import { Button, Flex } from "src/elements";
import { PlanogramHovererDetails } from "src/components/PlanogramExtensions/PlanogramHovererDetails/PlanogramHovererDetails";
import { PlanogramMetrics } from "src/components/PlanogramExtensions/PlanogramMetrics";
import { routeToUrl } from "src/utils/axios";
import { pages } from "src/utils";
import { RoutePostMerchflows_merchflowId_BasePog } from "@CommonApi/merchflows/_merchflowId_/base-pog";
import { useNavigation } from "src/utils/navigation";

export const PageMerchflows_merchflowId_S2Solution_s2SolutionId_ = () => {
  const { merchflowId: _merchflowId, s2SolutionId: _s2SolutionId } = useParams<{
    merchflowId: string;
    s2SolutionId: string;
  }>();
  const merchflowId = Number(_merchflowId);
  const s2SolutionId = Number(_s2SolutionId);

  const { getS2SolutionApi, postStartMerchflowBasePogApi } = useApi();
  const { isInlineLoading } = useInlineLoaders();
  const { navigate } = useNavigation();

  const [data, setData] =
    useAsyncState<ResponseGetMerchflows_merchflowId_S2_s2SolutionId_>();
  const [pog, setPog] = useAtom(
    merchflows_merchflowId_S2Solution_s2SolutionId_PogAtom,
  );
  const [containers, setContainers] = useAtom(
    merchflows_merchflowId_S2Solution_s2SolutionId_ContainersAtom,
  );

  const isLoading =
    data === undefined ||
    isInlineLoading(RouteGetMerchflows_merchflowId_S2_s2SolutionId_) ||
    isInlineLoading(RoutePostMerchflows_merchflowId_BasePog);

  useEffect(() => {
    loadS2Solution();

    return () => {
      setPog(null);
      setContainers(null);
    };
  }, []);

  const loadS2Solution = async () => {
    const response = await getS2SolutionApi({ merchflowId, s2SolutionId });

    setData(response);

    if (response) {
      setPog(adapterPlanogramNonCircularToPog(response.solution.planogram));
      setContainers(response.solution.containers);
    }
  };

  const generateBasePog = async () => {
    if (!containers || !pog) {
      return;
    }
    const response = await postStartMerchflowBasePogApi(
      merchflowId,
      s2SolutionId,
      containers,
      adapterPogToPogNonCircular(pog),
    );

    if (response?.base_pog_id) {
      navigate(pages.merchflows_merchflowId_BasePog_basePogId_, {
        merchflowId: merchflowId,
        basePogId: response?.base_pog_id,
      });
    }
  };

  return (
    <Page>
      <MerchflowHeader
        merchflowId={merchflowId}
        templateId={data?.template_id}
      />

      <MerchflowStepsNavigator
        activeStep="S2"
        s1UserTriggerId={data?.s1_user_trigger_id}
        templateId={data?.template_id}
        merchflowId={merchflowId}
      />

      {isLoading && (
        <PageStatus label="Loading mudmap solution..." icon="spinner" />
      )}

      {data && pog && containers && (
        <Flex column grow gap="10px">
          <Flex grow>
            <Planogram
              pogAtom={
                merchflows_merchflowId_S2Solution_s2SolutionId_PogAtom as unknown as PrimitiveAtom<Pog>
              }
              containersAtom={
                merchflows_merchflowId_S2Solution_s2SolutionId_ContainersAtom as unknown as PrimitiveAtom<
                  PlanogramContainer[]
                >
              }
              isEditable
              merchflowId={merchflowId}
              isWithBorder
              isContainersFullDetails
              isMetricsRecalculated={false}
            />
          </Flex>

          <PlanogramHovererDetails />

          <Flex justify="between" align="center">
            <PlanogramMetrics pog={pog} isLoading={isLoading} />

            <Flex gap="10px">
              <Button
                color="greenSmoke"
                variant="inverted"
                url={routeToUrl(
                  pages.merchflows_merchflowId_S2_s1UserTriggerId_Templates_templateId_,
                  {
                    merchflowId,
                    s1UserTriggerId: data.s1_user_trigger_id,
                    templateId: data.template_id,
                  },
                )}
              >
                Back
              </Button>

              <Button
                color="primary"
                isLoading={isLoading}
                onClick={generateBasePog}
              >
                Generate Base Planogram
              </Button>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Page>
  );
};
