import { useAtomValue } from "jotai";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Page } from "src/components";
import { useInlineLoaders } from "src/components/InlineLoader";
import {
  Button,
  Color,
  Flex,
  Icon,
  Link,
  Scroller,
  Spinner,
  Text,
} from "src/elements";
import { useBasePogApi } from "src/hooks";
import { MerchflowHeader } from "../../components/merchflows/MerchflowHeader/MerchflowHeader";
import { basePogTemplateAtoms } from "./store/atom";

import {
  PogOverview,
  OneTemplateViewStatus,
} from "../Merchflows_merchflowId_Stores_storeCode/components/PogOverview";
import { routeToUrl } from "src/utils/axios";
import { pages } from "src/utils";
import { RoutePostMerchflows_MerchflowId_ExportsBatch } from "@CommonApi/merchflows/_merchflowId_/exports/batch";
import { useApi } from "src/api";
import { RoutePutBasePogSelectedStatus } from "@CommonApi/pogger/base-pog/status";
import { useBasePlanogramReviewModal } from "src/modals/BasePlanogramReview/store/hooks";
import { BasePogWithId } from "./store/types";

export const PageMerchflowTemplateBasePogs = () => {
  const { merchflowId: _merchflowId, templateId: _templateId } = useParams<{
    merchflowId: string;
    templateId: string;
  }>();
  const merchflowId = Number(_merchflowId);
  const templateId = String(_templateId);
  const curRef = useRef({ merchflowId: 0, templateId: "" });

  const { postExportBatchPogsApi, putBasePogStatusApi } = useApi();
  const { loadAllBasePogOfTemplate } = useBasePogApi();
  const { isInlineLoading } = useInlineLoaders();
  const basePogs = useAtomValue(basePogTemplateAtoms);
  const [selection, setSelection] = useState<number[]>([]);

  const isBasePogListLoading = isInlineLoading("loadAllBasePogOfTemplate");
  const isFavoriteLoading = isInlineLoading(RoutePutBasePogSelectedStatus);
  const isExportLoading = isInlineLoading(
    RoutePostMerchflows_MerchflowId_ExportsBatch,
  );
  const { openBasePlanogramReviewModal } = useBasePlanogramReviewModal();

  const showWarningBasePogs = basePogs.reduce((result, basePog) => {
    if (basePog.is_favourite) {
      //@ts-ignore
      result[basePog.id] = true;
    }
    return result;
  }, {});

  const onClickBasePog = (basePogWithId: BasePogWithId) => {
    openBasePlanogramReviewModal({
      merchflowId,
      basePogId: basePogWithId.id,
      basePog: basePogWithId.pog_data,
    });
  };

  const onClickFavorite = async (selection: number[]) => {
    const response = await putBasePogStatusApi({
      is_favourite: selection,
    });

    if (response) {
      loadAllBasePogOfTemplate(templateId, merchflowId);
      setSelection([]);
    }
  };

  const onClickUnFavorite = async (selection: number[]) => {
    const response = await putBasePogStatusApi({
      is_not_favourite: selection,
    });

    if (response) {
      loadAllBasePogOfTemplate(templateId, merchflowId);
      setSelection([]);
    }
  };

  const exportSelectedStorePogs = async () => {
    const response = await postExportBatchPogsApi(merchflowId, {
      type: "psa",
      base_pog_ids: selection,
    });

    if (response?.status === "DONE") {
      setSelection([]);
    }
  };

  useEffect(() => {
    const { merchflowId: curMerchflowId, templateId: curTemplateId } =
      curRef.current;

    if (merchflowId !== curMerchflowId || templateId !== curTemplateId) {
      loadAllBasePogOfTemplate(templateId, merchflowId);
      curRef.current = {
        merchflowId,
        templateId,
      };
    }
  }, [templateId, merchflowId]);

  return (
    <Page>
      <MerchflowHeader merchflowId={merchflowId} />

      <Flex minHeight="0" grow column gap="5px">
        <Flex justify="space-between" align="center">
          <Link to={routeToUrl(pages.merchflowTemplates, { merchflowId })}>
            <Flex height="24px" align="center" gap="5px">
              <Icon name="arrowLeft" />

              <Text variant="small1" color={Color.textMain}>
                Back to Review Screen
              </Text>
            </Flex>
          </Link>
          <Flex gap="10px">
            <Button
              isLoading={isFavoriteLoading}
              dropdown={[
                {
                  label: "Favorite selected",
                  onClick: () => onClickFavorite(selection),
                  isDisabled: selection.length === 0,
                },
                {
                  label: "Un-favorite selected",
                  onClick: () => onClickUnFavorite(selection),
                  isDisabled: selection.length === 0,
                },
              ]}
            >
              Favorite
            </Button>

            <Button
              isLoading={isExportLoading}
              dropdown={[
                {
                  label: "Export selected",
                  onClick: exportSelectedStorePogs,
                  isDisabled: selection.length === 0,
                },
              ]}
            >
              Exports
            </Button>
          </Flex>
        </Flex>

        <Flex
          minHeight="0"
          grow
          borderColor={Color.lightGrayHover}
          borderRadius="3px"
        >
          {!isBasePogListLoading ? (
            <Scroller>
              <Flex padding="20px" gap="20px" flexWrap="wrap">
                {basePogs.map((basePog, i) => (
                  <Flex
                    key={i}
                    maxWidth="50%"
                    position="relative"
                    onClick={() => onClickBasePog(basePog)}
                  >
                    <PogOverview
                      pog={basePog.pog_data}
                      label={`Basepog: ${basePog.id}`}
                      selectionId={basePog.id}
                      selection={selection}
                      setSelection={setSelection}
                      status={
                        Object.values(showWarningBasePogs).length > 1 &&
                        //@ts-ignore
                        showWarningBasePogs[basePog.id]
                          ? OneTemplateViewStatus.MULTIPLE_BASE_POG_FAVOURITE
                          : undefined
                      }
                      loadingMessage="Loading template..."
                    />
                    <Flex position="absolute" top="-10px" left="-10px">
                      <Icon
                        name={
                          basePog.is_favourite ? "starFilled" : "starUnfilled"
                        }
                      />
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            </Scroller>
          ) : (
            <Flex width="100%" align="center" justify="center">
              <Spinner size="big" />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Page>
  );
};
