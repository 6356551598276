export type Category = string;
export type SubCategory = string;

export const enum NewFlowReviewStep {
  STEP_1_1_INPUTS = "inputs",
  STEP_1_2_FILTERS = "filters",
  STEP_1_3_CDT = "cdt",
  STEP_1_4_MERGE_RULES = "mergeRules",
}

export const enum OverrideCDT {
  NO = "no",
  YES = "yes",
}

export const enum Flows {
  UNASSIGNED_FLOWS = "unassigned_flows",
  ALL_FLOWS = "all_flows",
}

export interface PayloadUploadCoreRange {
  file: File;
  categoryId: number;
  pmUploadId: number;
}

export interface MerchFlowLoadStore {
  bay_size: number;
  state: string;
  section_height: number;
  section_width: number;
  cluster: string;
  retailer_category_code: string;
  category_code: string;
  store: string;
}

export interface MerchFlowApplyStore extends MerchFlowLoadStore {
  ffUploadId: number;
  pmUploadId: number;
  posUploadId: number;
  template_id: string;
}

export type ResponseLoadVariants = string[];

export interface MergeRule {
  variants: string[];
}

export interface PayloadApplyMergeRules {
  filterConfigId: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  cdt: {};
  hierarchy: {
    "Core Range": number;
    DOS: number;
    MOS: number;
  };
  merge_rules: {
    rule_id: number;
    merged_variants: string[];
  }[];
}
