/* eslint-disable no-param-reassign */
import { AlgoS2FreezerOutput } from "@CommonTypes/algo/s2";
import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";
import { S2Solution } from "@CommonTypes/merchflow/pog/solution";
import {
  algoS2FreezerItemLabelCdtsRegex,
  getContainerColor,
  getRandomDarkerColor,
} from "@CommonUtils/pog/container";

import { Pm } from "@CommonTypes/owa-db/merchantSchema";
import {
  PlanogramPegboard,
  PlanogramPegboardItem,
} from "@CommonTypes/merchflow/pog/pegboard";
import { PoggerItem, PoggerResponse, PoggerShelf } from "../types/algo/pogger";
import { PoggerizeResponse, PoggerizeShelf } from "../types/algo/poggerize";
import { Fixture, FixtureCanCombine } from "../types/backend/fixture";
import { ProductMaster } from "../types/backend/productMaster";
import {
  Pog,
  PogNonCircular,
  Planogram,
  PlanogramBay,
  PlanogramBayNonCircular,
  PlanogramItem,
  PlanogramItemNonCircular,
  PlanogramNonCircular,
  latestPogVersion,
  PlanogramItemOrientation,
} from "../types/merchflow/pog/pog";
import {
  PlanogramShelf,
  PlanogramShelfNonCircular,
} from "../types/merchflow/pog/shelf";
import {
  calculatePogCoreRange,
  calculatePogDosMos,
  calculatePogReturnOnSpace,
  calculatePogShelfAlignment,
  calculateS2ReturnOnSpace,
  getPlanogramShelf,
} from "../utils/pog/pog";
import { getShelfYBottom } from "../utils/pog/shelf";
import { uuid } from "../utils/uuid";

export const buildPogFromFixtures = (fixtures: Fixture[]) => {
  const pog: Pog = {
    version: latestPogVersion,
    planogram: buildPlanogramFromFixtures(fixtures),
    unrangedItems: [],
    deletedItems: [],
    metrics: {
      returnOnSpace: null,
      shelfAlignment: null,
      coreRange: null,
      dosMos: null,
    },
  };

  pog.metrics = calculatePogMetrics(pog);

  return pog;
};

export const calculatePogMetrics = (pog: Pog) => ({
  returnOnSpace: calculatePogReturnOnSpace(pog),
  coreRange: calculatePogCoreRange(pog),
  shelfAlignment: calculatePogShelfAlignment(pog),
  dosMos: calculatePogDosMos(pog),
});

export const buildPlanogramFromFixtures = (fixtures: Fixture[]) => {
  // Map to hold shelf/fixture links to merge built shelf objects based on fixtures later.
  const fixturesShelves: {
    [shelfId: string]: {
      fixture: Fixture;
      shelf: PlanogramShelf;
    };
  } = {};

  const planogram: Planogram = {
    width: 0,
    height: 0,
    bays: [],
  };

  let bay: PlanogramBay | null = null;

  fixtures
    .sort((f1, f2) => {
      if (f1.section_num !== f2.section_num) {
        return f1.section_num - f2.section_num;
      }
      return f1.shelf_num - f2.shelf_num;
    })
    .forEach((fixture, fixtureIndex) => {
      // First bay.
      if (bay === null) {
        bay = {
          planogram,
          uniqueId: uuid(),
          xLeft: 0,
          xRight: fixture.section_width,
          yBottom: 0,
          yTop: fixture.section_height,
          bayNo: fixture.section_num,
          notchOffset:
            fixture.planogram_notch_offset ??
            fixture.others.planogram_notch_offset,
          baseHeight:
            fixture.planogram_base_height ??
            fixture.others.planogram_base_height,
          notchSpacing:
            fixture.planogram_notch_spacing ??
            fixture.others.planogram_notch_spacing,
          maxNotch: fixture.max_notch_owa ?? fixture.others.max_notch_owa,
          depth: fixture.shelf_depth,
          shelves: [],
          pegboards: [],
        };
        // New bay.
      } else if (fixture.section_num !== bay.bayNo) {
        planogram.bays.push(bay);
        planogram.width = planogram.width + bay.xRight - bay.xLeft;
        planogram.height = Math.max(bay.yTop, planogram.height);

        const previousBay = bay;

        bay = {
          planogram,
          uniqueId: uuid(),
          xLeft: previousBay.xRight,
          xRight: previousBay.xRight + fixture.section_width,
          yBottom: 0,
          yTop: fixture.section_height,
          bayNo: fixture.section_num,
          notchOffset:
            fixture.planogram_notch_offset ??
            fixture.others.planogram_notch_offset,
          baseHeight:
            fixture.planogram_base_height ??
            fixture.others.planogram_base_height,
          notchSpacing:
            fixture.planogram_notch_spacing ??
            fixture.others.planogram_notch_spacing,
          maxNotch: fixture.max_notch_owa ?? fixture.others.max_notch_owa,
          depth: fixture.shelf_depth,
          shelves: [],
          pegboards: [],
        };
      }

      if (fixture.shelf_type === "PEGBOARD") {
        const pegboard: PlanogramPegboard = {
          bay,
          items: [],
          uniqueId: String(fixture.fixture_id ?? fixture.others.fixture_id),
          yBottom: fixture.shelf_y,
          height: fixture.shelf_thickness,
          holesOffsetHorizontal: Number(fixture.peg_notch_offset_x!) * 100,
          holesOffsetVertical: Number(fixture.peg_notch_offset_y!) * 100,
          holesGapHorizontal: Number(fixture.peg_notch_spacing_x!) * 100,
          holesGapVertical: Number(fixture.peg_notch_spacing_y!) * 100,
        };

        bay.pegboards!.push(pegboard);
      }

      if (fixture.shelf_type !== "PEGBOARD") {
        const merchandisableShelf =
          fixture.merchandisable_shelf ?? fixture.others.merchandisable_shelf;
        const shelf: PlanogramShelf = {
          bay,
          uniqueId: String(fixture.fixture_id ?? fixture.others.fixture_id),
          width: fixture.section_width,
          thickness: fixture.shelf_thickness,
          depth: fixture.shelf_depth,
          type:
            (merchandisableShelf &&
              fixture.shelf_type === "HANGCELL" &&
              "HANGCELL") ||
            (merchandisableShelf &&
              fixture.shelf_type === "REGULAR" &&
              "REGULAR") ||
            "RISER",
          offset:
            fixture.shelf_adjustment_notch_offset_calc ??
            fixture.others.shelf_adjustment_notch_offset_calc,
          notchNo: fixture.notch_num,
          shelfNo: fixture.shelf_num,
          mergedLeft: null,
          mergedRight: null,
          items: [],
        };

        bay.shelves.push(shelf);
        fixturesShelves[
          String(fixture.fixture_id ?? fixture.others.fixture_id)
        ] = {
          fixture,
          shelf,
        };
      }

      // Last fixture of the fixtures.
      if (fixtureIndex === fixtures.length - 1) {
        planogram.bays.push(bay);
        planogram.width = planogram.width + bay.xRight - bay.xLeft;
        planogram.height = Math.max(bay.yTop, planogram.height);
      }
    });

  // Sort all planogram bays shelves.
  for (const bay of planogram.bays) {
    bay.shelves = bay.shelves.sort((shelf1, shelf2) =>
      shelf1.notchNo < shelf2.notchNo ? -1 : 1,
    );
  }

  // Merge shelves according to fixtures rules.
  for (let bayIndex = 0; bayIndex < planogram.bays.length; bayIndex++) {
    const bay = planogram.bays[bayIndex];

    for (const shelf of bay.shelves) {
      const { fixture } = fixturesShelves[shelf.uniqueId];

      // Shelf is not mergeable to right.
      if (
        fixture.can_combine !== FixtureCanCombine.BOTH &&
        fixture.can_combine !== FixtureCanCombine.RIGHT
      ) {
        continue;
      }

      const nextBay: PlanogramBay | undefined = planogram.bays[bayIndex + 1];

      // Next bay not found, current one is last bay.
      if (!nextBay) continue;

      for (const nextBayShelf of nextBay.shelves) {
        const nextFixture = fixturesShelves[nextBayShelf.uniqueId].fixture;

        // Next bay shelf is not mergeable to left.
        if (
          nextFixture.can_combine !== FixtureCanCombine.BOTH &&
          nextFixture.can_combine !== FixtureCanCombine.LEFT
        ) {
          continue;
        }

        if (
          // Shelves bottoms start from same coordinate.
          getShelfYBottom(shelf) === getShelfYBottom(nextBayShelf) &&
          // Shelves heights need to match.
          // getShelfYTop(shelf) === getShelfYTop(nextBayShelf) &&

          // Shelves thicknesses need to match.
          shelf.thickness === nextBayShelf.thickness
        ) {
          shelf.mergedRight = nextBayShelf;
          nextBayShelf.mergedLeft = shelf;
        }
      }
    }
  }

  return planogram;
};

export const getPogFromNonCircularOrFixtures = (
  poggerizeResponse: PoggerizeResponse,
  ff: Fixture[],
) => {
  // Use poggerize passtrough to get pog.
  if (poggerizeResponse.passthrough?.bays) {
    const nonCircularPlanogram = poggerizeResponse.passthrough;

    const pog: Pog = {
      version: latestPogVersion,
      planogram: {
        width: nonCircularPlanogram.width,
        height: nonCircularPlanogram.height,
        bays: [],
      },
      unrangedItems: [],
      metrics: {
        returnOnSpace: null,
        shelfAlignment: null,
        coreRange: null,
        dosMos: null,
      },
      deletedItems: [],
    };

    // Build circular pog back together.
    for (const nonCircularBay of nonCircularPlanogram.bays) {
      const bay: PlanogramBay = {
        planogram: pog.planogram,
        uniqueId: nonCircularBay.uniqueId,
        bayNo: nonCircularBay.bayNo,
        depth: nonCircularBay.depth,
        baseHeight: nonCircularBay.baseHeight,
        notchOffset: nonCircularBay.notchOffset,
        notchSpacing: nonCircularBay.notchSpacing,
        maxNotch: nonCircularBay.maxNotch,
        xLeft: nonCircularBay.xLeft,
        xRight: nonCircularBay.xRight,
        yBottom: nonCircularBay.yBottom,
        yTop: nonCircularBay.yTop,
        shelves: [],
        pegboards: [],
      };

      for (const nonCircularShelf of nonCircularBay.shelves) {
        const shelf: PlanogramShelf = {
          ...nonCircularShelf,
          bay,
          mergedLeft: null,
          mergedRight: null,
          items: [],
        };

        bay.shelves.push(shelf);
      }

      pog.planogram.bays.push(bay);
    }

    pog.metrics = calculatePogMetrics(pog);

    return pog;
  }

  // Rebuild pog from fixtures.
  const noPassThroughPog: Pog = {
    version: latestPogVersion,
    planogram: buildPlanogramFromFixtures(ff),
    unrangedItems: [],
    deletedItems: [],
    metrics: {
      returnOnSpace: null,
      shelfAlignment: null,
      coreRange: null,
      dosMos: null,
    },
  };
  noPassThroughPog.metrics = calculatePogMetrics(noPassThroughPog);

  return noPassThroughPog;
};

export const buildPog = ({
  poggerizeResponse,
  poggerResponse,
  pmData,
  ffData,
}: {
  poggerizeResponse: PoggerizeResponse;
  poggerResponse: PoggerResponse;
  pmData: ProductMaster[];
  ffData: Fixture[];
}): Pog => {
  const pog: Pog = getPogFromNonCircularOrFixtures(poggerizeResponse, ffData);
  return convertToPogFormat(poggerizeResponse, poggerResponse, pmData, pog);
};

const convertToPogFormat = (
  poggerizeResponse: PoggerizeResponse,
  poggerResponse: PoggerResponse,
  pmData: ProductMaster[],
  pogTemplate: Pog,
  mergeShelvesBackFromPoggerizePassthrough = true,
): Pog => {
  // Add unranged items.
  const pog = { ...pogTemplate };
  pog.unrangedItems = poggerResponse.meta.unranged_items
    .map(({ item_id }) => {
      const pmItem = pmData.find((pm) => pm.product_code === item_id);

      if (pmItem) return convertPoggerItemToPlanogramItem({ pmItem });

      // eslint-disable-next-line no-console
      console.debug(`Unranged item ${item_id} was not found from PM data`);
      return null;
    })
    .filter((item) => item !== null) as PlanogramItem[];

  const combinedShelves: {
    [shelfId: string]: {
      poggerizePoggerShelves: {
        poggerShelf: PoggerShelf;
        poggerizeShelf: PoggerizeShelf;
      }[];
      shelf: PlanogramShelf;
    };
  } = {};

  // Find all matching pogger, poggerize and fixtures.
  for (const poggerBay of poggerResponse.planogram.bays) {
    for (const poggerShelf of poggerBay.shelves) {
      const poggerizeShelf = poggerizeResponse.layout.bays
        .find((poggerizeBay) => poggerizeBay.bay_no === poggerBay.bay_no)
        ?.shelves.find(
          (poggerizeShelf) => poggerizeShelf.shelf_no === poggerShelf.shelf_no,
        );

      if (!poggerizeShelf) {
        // eslint-disable-next-line no-console
        console.debug(
          `Poggerize shelf bay_no ${poggerBay.bay_no}, shelf_no ${poggerShelf.shelf_no} was not found`,
        );
        continue;
      }

      let shelf: PlanogramShelf | null = null;

      bayLooper: for (const _bay of pog.planogram.bays) {
        for (const _shelf of _bay.shelves) {
          if (_shelf.uniqueId === poggerizeShelf.shelf_id) {
            shelf = _shelf;
            break bayLooper;
          }
        }
      }

      if (!shelf) {
        // eslint-disable-next-line no-console
        console.debug(
          `Non circular shelf with id ${poggerizeShelf.shelf_id} not found`,
        );
        continue;
      }

      if (combinedShelves[poggerizeShelf.shelf_id]) {
        combinedShelves[poggerizeShelf.shelf_id].poggerizePoggerShelves.push({
          poggerShelf,
          poggerizeShelf,
        });
      } else {
        combinedShelves[poggerizeShelf.shelf_id] = {
          poggerizePoggerShelves: [{ poggerShelf, poggerizeShelf }],
          shelf,
        };
      }
    }
  }

  // Sort all combined shelves to make sure order of items is correct later.
  Object.values(combinedShelves).forEach((combinedShelf) => {
    combinedShelf.poggerizePoggerShelves =
      combinedShelf.poggerizePoggerShelves.sort((s1, s2) =>
        s1.poggerizeShelf.shelf_x < s2.poggerizeShelf.shelf_x ? -1 : 1,
      );
  });

  // Build all PlanogramShelf objects.
  Object.values(combinedShelves).forEach(
    ({ shelf, poggerizePoggerShelves }) => {
      // Build all PlanogramItem objects and add them to the built shelf.
      Object.values(poggerizePoggerShelves).forEach(({ poggerShelf }) => {
        shelf.shelfNo = poggerShelf.shelf_no;
        shelf.items = [
          ...shelf.items,
          ...poggerShelf.items
            .map((poggerItem) => {
              const pmItem = pmData.find(
                (pm) => pm.product_code === poggerItem.item_id,
              )!;

              if (!pmItem) {
                // eslint-disable-next-line no-console
                console.debug(
                  `Item ${poggerItem.item_id} was not found from PM data`,
                );
                return null;
              }

              return convertPoggerItemToPlanogramItem({
                pmItem,
                poggerItem,
                shelf,
              });
            })
            .filter((item): item is PlanogramItem => Boolean(item)),
        ];
      });
    },
  );

  // Merge shelves back from poggerize passtrough.
  if (mergeShelvesBackFromPoggerizePassthrough) {
    if (poggerizeResponse.passthrough?.bays) {
      for (const nonCircularBay of poggerizeResponse.passthrough.bays) {
        for (const nonCircularShelf of nonCircularBay.shelves) {
          const leftShelfId = nonCircularShelf.mergedLeft;
          const rightShelfId = nonCircularShelf.mergedRight;

          let shelf: PlanogramShelf | undefined;
          let leftShelf: PlanogramShelf | null = null;
          let rightShelf: PlanogramShelf | null = null;

          for (const _bay of pog.planogram.bays) {
            for (const _shelf of _bay.shelves) {
              if (_shelf.uniqueId === leftShelfId) {
                leftShelf = _shelf;
              }
              if (_shelf.uniqueId === rightShelfId) {
                rightShelf = _shelf;
              }
              if (_shelf.uniqueId === nonCircularShelf.uniqueId) {
                shelf = _shelf;
              }
            }
          }

          if (shelf) {
            shelf.mergedLeft = leftShelf;
            shelf.mergedRight = rightShelf;
          }
        }
      }
    }
  }

  pog.metrics = calculatePogMetrics(pog);

  return pog;
};

export const adapterPogToPogNonCircular = (pog: Pog) => {
  const pogNonCircular: PogNonCircular = {
    version: pog.version,
    planogram: adapterPlanogramToPlanogramNonCircular(pog.planogram),
    unrangedItems: pog.unrangedItems.map((item) => {
      const itemNonCircular: PlanogramItemNonCircular = {
        ...item,
        shelf: item.shelf?.uniqueId || null,
      };

      return itemNonCircular;
    }),
    metrics: pog.metrics,
    deletedItems: pog.deletedItems?.map((item) => ({ ...item, shelf: null })),
  };

  return pogNonCircular;
};

export const adapterPlanogramToPlanogramNonCircular = (
  planogram: Planogram,
) => {
  const planogramNonCircular: PlanogramNonCircular = {
    width: planogram.width,
    height: planogram.height,
    bays: planogram.bays.map((bay) => {
      const bayNonCircular: PlanogramBayNonCircular = {
        uniqueId: bay.uniqueId,
        bayNo: bay.bayNo,
        depth: bay.depth,
        baseHeight: bay.baseHeight,
        notchOffset: bay.notchOffset,
        notchSpacing: bay.notchSpacing,
        maxNotch: bay.maxNotch,
        xLeft: bay.xLeft,
        xRight: bay.xRight,
        yBottom: bay.yBottom,
        yTop: bay.yTop,
        shelves: bay.shelves.map((shelf) => {
          const shelfNonCircular: PlanogramShelfNonCircular = {
            ...shelf,
            bay: shelf.bay.uniqueId,
            mergedLeft: shelf.mergedLeft?.uniqueId || null,
            mergedRight: shelf.mergedRight?.uniqueId || null,
            items: shelf.items.map((item) => {
              const itemNonCircular: PlanogramItemNonCircular = {
                ...item,
                shelf: item.shelf?.uniqueId || null,
              };

              return itemNonCircular;
            }),
          };

          return shelfNonCircular;
        }),
        // Safely check that pegboards exist, because old pogs don't have it.
        pegboards: bay.pegboards
          ? bay.pegboards.map((pegboard) => ({
              ...pegboard,
              bay: bay.uniqueId,
              items: pegboard.items.map((item) => ({
                ...item,
                pegboard: pegboard.uniqueId,
              })),
            }))
          : [],
      };

      return bayNonCircular;
    }),
  };

  return planogramNonCircular;
};

export const adapterPlanogramNonCircularToPog = (
  planogramNonCircular: PlanogramNonCircular,
) => {
  const pog: Pog = {
    planogram: adapterPlanogramNonCircularToPlanogram(planogramNonCircular),
    unrangedItems: [],
    deletedItems: [],
    version: "",
    metrics: {
      returnOnSpace: null,
      shelfAlignment: null,
      coreRange: null,
      dosMos: null,
    },
  };
  return pog;
};

export const adapterPlanogramNonCircularToPlanogram = (
  planogramNonCircular: PlanogramNonCircular,
) => {
  const planogram: Planogram = {
    width: planogramNonCircular.width,
    height: planogramNonCircular.height,
    bays: [],
  };

  planogram.bays = planogramNonCircular.bays.map((bayNonCircular) => ({
    ...bayNonCircular,
    planogram,
    shelves: [],
    pegboards: [],
  }));

  for (const bayNonCircular of planogramNonCircular.bays) {
    // Build shelves.
    for (const shelfNonCircular of bayNonCircular.shelves) {
      const bay = planogram.bays.find(
        (bay) => bay.uniqueId === shelfNonCircular.bay,
      )!;

      // Convert non-circular shelf to circular, assign correct bay to it.
      const shelf: PlanogramShelf = {
        ...shelfNonCircular,
        bay,
        mergedLeft: null,
        mergedRight: null,
        items: [],
      };

      // Convert non-circular item to circular, assign correct shelf to it.
      for (const shelfItemNonCircular of shelfNonCircular.items) {
        const shelfItem: PlanogramItem = {
          ...shelfItemNonCircular,
          shelf,
        };

        // Add item to the shelf.
        shelf.items.push(shelfItem);
      }

      // Add shelf to the bay.
      bay.shelves.push(shelf);
    }

    // Build pegboards.
    // Safely check that pegboards exist, because old pogs don't have it.
    if (bayNonCircular.pegboards) {
      for (const pegboardNonCircular of bayNonCircular.pegboards) {
        const bay = planogram.bays.find(
          (bay) => bay.uniqueId === pegboardNonCircular.bay,
        )!;

        const pegboard: PlanogramPegboard = {
          ...pegboardNonCircular,
          items: [],
          bay,
        };

        // Connect pegboard items to pegboards.
        for (const pegboardItemNonCircular of pegboardNonCircular.items) {
          const pegboardItem: PlanogramPegboardItem = {
            ...pegboardItemNonCircular,
            pegboard,
          };

          pegboard.items.push(pegboardItem);
        }

        bay.pegboards?.push(pegboard);
      }
    }
  }

  // Merge shelves back together correctly.
  for (const bayNonCircular of planogramNonCircular.bays) {
    for (const shelfNonCircular of bayNonCircular.shelves) {
      if (shelfNonCircular.mergedRight) {
        const shelf = getPlanogramShelf(planogram, shelfNonCircular.uniqueId);
        const shelfRight = getPlanogramShelf(
          planogram,
          shelfNonCircular.mergedRight,
        );

        // Assign correct shelf objects to each other.
        if (shelf && shelfRight) {
          shelf.mergedRight = shelfRight;
          shelfRight.mergedLeft = shelf;
        }
      }
    }
  }

  return planogram;
};

export const adapterPogNonCircularToPog = (pogNonCircular: PogNonCircular) => {
  const pog: Pog = {
    version: pogNonCircular.version,
    planogram: adapterPlanogramNonCircularToPlanogram(pogNonCircular.planogram),
    unrangedItems: pogNonCircular.unrangedItems.map((itemNonCircular) => ({
      ...itemNonCircular,
      shelf: null,
    })),
    deletedItems: pogNonCircular.deletedItems?.map((item) => ({
      ...item,
      shelf: null,
    })),
    metrics: pogNonCircular.metrics,
  };

  return pog;
};

const convertPoggerItemToPlanogramItem = ({
  poggerItem,
  pmItem,
  shelf,
}: {
  pmItem: ProductMaster;
  poggerItem?: PoggerItem;
  shelf?: PlanogramShelf;
}) => {
  const item: PlanogramItem = {
    shelf: shelf || null,
    uniqueId: uuid(),
    id: pmItem.id,
    productCode: pmItem.product_code,
    name: pmItem.name,
    upc: pmItem.others.upc,
    brand: pmItem.brand,
    variant: pmItem.variant,
    categoryCode: pmItem.category_code,
    subCategory: pmItem.subcategory,
    size: pmItem.size ?? "0",
    color: pmItem.others.colour,
    profit: Number(pmItem.profit),
    quantity: Number(pmItem.quantity),
    price: Number(pmItem.others.price),
    salesAmount: Number(pmItem.sales_amount),
    orientation:
      (poggerItem?.orientation.toUpperCase() as PlanogramItemOrientation) ||
      splitToValidOrientations(pmItem.orientation)[0],
    validOrientations: pmItem.valid_orientations,
    merchandisingStyle: pmItem.merchandising_style,
    unitWidth: Number(pmItem.unit_width),
    unitHeight: Number(pmItem.unit_height),
    unitDepth: Number(pmItem.unit_depth),
    trayWidth: Number(pmItem.tray_width),
    trayHeight: Number(pmItem.tray_height),
    trayDepth: Number(pmItem.tray_depth),
    caseWidth: Number(pmItem.case_width),
    caseHeight: Number(pmItem.case_height),
    caseDepth: Number(pmItem.case_depth),
    displayWidth: Number(pmItem.others.display_width),
    displayHeight: Number(pmItem.others.display_height),
    displayDepth: Number(pmItem.others.display_depth),
    noOfUnitsInCase: Number(pmItem.no_of_units_in_case),
    unitsCaseWide: Number(pmItem.units_case_wide),
    unitsCaseDeep: Number(pmItem.units_case_deep),
    unitsCaseHigh: Number(pmItem.units_case_high),
    noOfUnitsInTray: Number(pmItem.no_of_units_in_tray),
    unitsTrayWide: Number(pmItem.units_tray_wide),
    unitsTrayDeep: Number(pmItem.units_tray_deep),
    unitsTrayHigh: Number(pmItem.units_tray_high),
    squeezeWidthPct: Number(pmItem.squeeze_width_pct),
    squeezeHeightPct: Number(pmItem.squeeze_height_pct),
    squeezeDepthPct: Number(pmItem.squeeze_depth_pct),
    overhangSqueezeDepthFlag:
      pmItem.others.overhang_squeeze_depth_flag === "True",
    cdt1: pmItem.cdt1,
    cdt1Priority: Number(pmItem.cdt1_priority),
    cdt2: pmItem.cdt2,
    cdt2Priority: Number(pmItem.cdt2_priority),
    cdt3: pmItem.cdt3,
    cdt3Priority: Number(pmItem.cdt3_priority),
    facings: poggerItem?.facings || 1,
    minFacings: pmItem.min_facings || 1,
    maxFacings: pmItem.max_facings || 100,
    facingsRows: poggerItem?.num_rows || 1,
    minFacingsRows: pmItem.min_stack || 1,
    maxFacingsRows: pmItem.max_stack || 1,
    minOnShelf: pmItem.min_on_shelf,
    minDaysOfSupply: Number(pmItem.min_days_of_supply),
    maxDaysOfSupply: Number(pmItem.max_days_of_supply),
    zCappingFlag: false,
    inCoreRange:
      Boolean(pmItem.others.core_range) &&
      pmItem.others.core_range.toString().toLowerCase() !== "false",
    newItemFlag: false,
    newItemStoreCodes: undefined,
    dndFlag: false,
  };

  return item;
};

/**
 * Splits a string by vertical line, comma, or semicolon.
 * Converts  to uppercase.
 * If no valid orientations are found, it defaults to ["FRONT"].
 * */
export const splitToValidOrientations = (
  orientationString: string | null,
): PlanogramItemOrientation[] => {
  if (!orientationString) return ["FRONT"];

  const delimiters = /[|,;]/;
  const orientations = orientationString
    .split(delimiters)
    .map((orientation) => orientation.trim().toUpperCase())
    .filter((orientation) => orientation.length > 0);

  return (
    orientations.length > 0 ? orientations : ["FRONT"]
  ) as PlanogramItemOrientation[];
};

export const adapterAlgoS2FreezerToPlanogramContainers = (
  freezer: AlgoS2FreezerOutput,
) => {
  const containers: PlanogramContainer[] = [];
  const usedCdtColors: { [key: string]: string } = {};

  freezer.sections.forEach((section) =>
    section.items.forEach((item) => {
      const cdts = algoS2FreezerItemLabelCdtsRegex.exec(item.item_id);
      if (!cdts || !cdts.groups) return;

      const { cdt0, cdt1 } = cdts.groups;
      const cdt0Cdt1 = `${cdt0}-${cdt1}`;

      // New CDT 0.
      if (!usedCdtColors[cdt0]) {
        const color = getContainerColor(cdt0, Object.values(usedCdtColors));

        usedCdtColors[cdt0] = color;
        usedCdtColors[cdt0Cdt1] = color;
      }

      // New CDT 1.
      if (!usedCdtColors[cdt0Cdt1]) {
        const color = getRandomDarkerColor(usedCdtColors[cdt0], 2);

        usedCdtColors[cdt0] = color;
        usedCdtColors[cdt0Cdt1] = color;
      }

      containers.push({
        uniqueId: uuid(),
        label: item.item_id,
        color: usedCdtColors[cdt0Cdt1],
        xLeft: item.start_x,
        xRight: item.start_x + item.width,
        yBottom: item.start_y,
        yTop: item.start_y + item.height,
      });
    }),
  );

  return containers;
};

/**
 * Adapter to build S2 solution from existing (modified fixtures in s0) or FF, based on for what template it is being built for.
 * @param fixtures - Fixtures to build the Pog structure from, if the fixturesModified was not provided.
 * @param pmList - list of pm items from DB.
 * @param freezer - Algo S2 response, from which the containers will be added to the S2Solution.
 * @param fixturesModified - Pog structure that has been generated only for the original template that the user did DND on.
 * @returns S2Solution, which consists of Pog (fixturesModified for original template, newly built for other templates) + Containers (from algo s2 response).
 */
export const buildS2Solution = (
  fixtures: Fixture[],
  pmList: Pm[],
  freezer: AlgoS2FreezerOutput,
  fixturesModified?: PogNonCircular,
): S2Solution => {
  const templatePog = fixturesModified
    ? adapterPogNonCircularToPog(fixturesModified)
    : buildPogFromFixtures(fixtures);

  const s2Solution: S2Solution = {
    pog: adapterPogToPogNonCircular(templatePog),
    containers: adapterAlgoS2FreezerToPlanogramContainers(freezer),
  };

  s2Solution.pog.metrics = {
    shelfAlignment: calculatePogShelfAlignment(templatePog),
    returnOnSpace: calculateS2ReturnOnSpace(freezer, pmList),
    coreRange: null,
    dosMos: null,
  };

  return s2Solution;
};

export const buildInitialBasePog = ({
  pmData,
  templatePog,
  poggerResponse,
  poggerizeResponse,
}: {
  pmData: ProductMaster[];
  templatePog: PogNonCircular;
  poggerResponse: PoggerResponse;
  poggerizeResponse: PoggerizeResponse;
}): Pog => {
  const pog = adapterPogNonCircularToPog(templatePog);
  return convertToPogFormat(
    poggerizeResponse,
    poggerResponse,
    pmData,
    pog,
    false,
  );
};

export const pogVersionAdapter = (pogNonCircular: PogNonCircular) => {
  const pog = adapterPogNonCircularToPog(pogNonCircular);

  const modifyItem = (item: PlanogramItem) => {
    // 2.1 - add profit to pog items
    if (item.profit === undefined) {
      item.profit = null;
    }
  };

  for (const bay of pog.planogram.bays) {
    for (const shelf of bay.shelves) {
      for (const item of shelf.items) {
        modifyItem(item);
      }
    }
  }
  for (const item of pog.unrangedItems) {
    modifyItem(item);
  }

  return adapterPogToPogNonCircular(pog);
};
