import { Button } from "src/elements";
import { useInlineLoaders } from "../InlineLoader";
import { useApi } from "src/api";
import {
  PayloadPostMerchflows_MerchflowId_ExportsBatch,
  RoutePostMerchflows_MerchflowId_ExportsBatch,
} from "@CommonApi/merchflows/_merchflowId_/exports/batch";
import { useState } from "react";
import { sleep } from "src/utils/sleep";

interface Props {
  merchflowId: number;
  storePogsIds: number[];
  isDisabled?: boolean;
}

export const StorePogsExporter = ({
  merchflowId,
  storePogsIds,
  isDisabled,
}: Props) => {
  const { isInlineLoading } = useInlineLoaders();
  const { postExportBatchPogsApi } = useApi();

  const [isSuccessful, setIsSuccessful] = useState(false);

  const isLoading = isInlineLoading(
    RoutePostMerchflows_MerchflowId_ExportsBatch,
  );

  const exportStorePogs = async (
    payload: PayloadPostMerchflows_MerchflowId_ExportsBatch,
  ) => {
    const data = await postExportBatchPogsApi(merchflowId, payload);

    if (data?.url) {
      setIsSuccessful(true);
      await sleep(1000);
      setIsSuccessful(false);
    }
  };

  return (
    <Button
      isLoading={isLoading}
      isSuccessful={isSuccessful}
      isDisabled={isDisabled}
      dropdown={[
        {
          label: "Export selected",
          isDisabled: storePogsIds.length === 0,
          buttons: [
            {
              label: "PDF",
              onClick: () =>
                exportStorePogs({ type: "pdf", store_pogs_ids: storePogsIds }),
            },
            {
              label: "CSV",
              onClick: () =>
                exportStorePogs({ type: "csv", store_pogs_ids: storePogsIds }),
            },
          ],
        },
        {
          label: "Export approved",
          buttons: [
            {
              label: "PDF",
              onClick: () =>
                exportStorePogs({ type: "pdf", approved_store_pogs: true }),
            },
            {
              label: "CSV",
              onClick: () =>
                exportStorePogs({ type: "csv", approved_store_pogs: true }),
            },
          ],
        },
      ]}
    >
      Exports
    </Button>
  );
};
