import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";
import { PogNonCircular } from "@CommonTypes/merchflow/pog/pog";

export const RoutePostMerchflows_merchflowId_BasePog =
  "/merchflows/:merchflowId/base-pog";

export interface PayloadPostMerchflows_merchflowId_BasePog {
  s2_solution_id: number;
  containers: PlanogramContainer[];
  pog_fixtures: PogNonCircular;
}

export interface ResponsePostMerchflows_merchflowId_BasePog {
  base_pog_id: number;
}
