import { useEffect, useState } from "react";
import { useSearchParams } from "src/utils/useSearchParams";

export const useQueryState = <T extends string | number | undefined>(
  key: string,
  defaultValue: T,
) => {
  const { searchParams, setSearchParam } = useSearchParams();

  useEffect(() => {
    if (searchParams[key] === undefined) {
      setInnerValue(defaultValue);
    } else {
      if (typeof defaultValue === "number") {
        setInnerValue(Number(searchParams[key]) as T);
      } else {
        setInnerValue(searchParams[key] as T);
      }
    }
  }, [searchParams]);

  const [innerValue, setInnerValue] = useState(
    (searchParams[key] as T) || defaultValue,
  );

  const setValue = (value: T) => {
    setSearchParam(key, value);
    setInnerValue(value);
  };

  return [innerValue, setValue] as [T, (value: T) => void];
};
