import { StoreVersionsView } from "@CommonApi/merchflows/_merchflowId_/stores/_storeCode_";
import { omniAtom } from "src/utils/atoms";

export const storePlanogramReviewAtom = omniAtom({
  key: "modals/storePlanogramReview",
  value: null as null | {
    merchflowId: number;
    storePlanograms: StoreVersionsView[];
    planogramIndex?: number;
  },
});
