import axios, { AxiosResponse } from "axios";
import { getLoginToken } from "src/utils";
import { env } from "./env";

const axiosInstance = axios.create();

const enum HTTPMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

const makeRequest = async ({
  url,
  method,
  contentType,
  xMsBlobType,
  payload,
  params,
  queryParams,
}: {
  url: string;
  method: HTTPMethod;
  contentType?:
    | "application/json"
    | "image/*"
    | "text/csv"
    | "multipart/form-data";
  xMsBlobType?: "BlockBlob";
  payload?: Record<string, any>;
  params?: Record<string, string | number>;
  queryParams?: Record<string, any>;
}) => {
  const headers = {
    "Content-Type": contentType || "application/json",
    ...(xMsBlobType ? { "x-ms-blob-type": "BlockBlob" } : {}),
    ...(getLoginToken() &&
    !(
      method === HTTPMethod.PUT &&
      contentType === "text/csv" &&
      xMsBlobType === "BlockBlob"
    )
      ? { Authorization: `Bearer ${getLoginToken()}` }
      : {}),
  };

  return axiosInstance({
    url: params ? routeToUrl(url, params) : url,
    method,
    data: payload,
    headers,
    params: queryParams,
  });
};

export const getRequest = <T>(
  url: string,
  data?: {
    queryParams?: Record<string, any>;
    params?: Record<string, string | number>;
  },
) => {
  return makeRequest({
    url,
    method: HTTPMethod.GET,
    params: data?.params,
    queryParams: data?.queryParams,
  }) as Promise<AxiosResponse<T>>;
};

export const postRequest = <T>(
  url: string,
  data?: {
    payload?: Record<string, any>;
    queryParams?: Record<string, any>;
    params?: Record<string, string | number>;
  },
) => {
  return makeRequest({
    url,
    method: HTTPMethod.POST,
    params: data?.params,
    payload: data?.payload,
    queryParams: data?.queryParams,
  }) as Promise<AxiosResponse<T>>;
};

export const putRequest = <T>(
  url: string,
  data: {
    payload: any;
    params?: Record<string, string | number>;
    queryParams?: Record<string, any>;
    contentType?: "application/json" | "image/*" | "text/csv";
    xMsBlobType?: "BlockBlob";
  },
) =>
  makeRequest({
    url,
    method: HTTPMethod.PUT,
    params: data.params,
    queryParams: data.queryParams,
    contentType: data.contentType,
    xMsBlobType: data.xMsBlobType,
    payload: data.payload,
  }) as Promise<AxiosResponse<T>>;

export const deleteRequest = <T>(
  url: string,
  data?: {
    queryParams?: Record<string, any>;
    params?: Record<string, string | number>;
  },
) =>
  makeRequest({
    url,
    method: HTTPMethod.DELETE,
    params: data?.params,
    queryParams: data?.queryParams,
  }) as Promise<AxiosResponse<T>>;

export const postFormDataRequest = <T>(
  url: string,
  data: {
    queryParams?: Record<string, any>;
    params?: Record<string, string | number>;
    payload: Record<string, any>;
  },
) => {
  const formData = new FormData();

  for (const [key, value] of Object.entries(data.payload)) {
    formData.append(key, value);
  }

  return makeRequest({
    url,
    method: HTTPMethod.POST,
    params: data.params,
    queryParams: data.queryParams,
    payload: formData,
    contentType: "multipart/form-data",
  }) as Promise<AxiosResponse<T>>;
};

export const routeToUrl = (
  route: string,
  params?: { [key: string]: string | number },
  searchParams?: { [key: string]: string | number | undefined },
) => {
  let url = route;

  const cleanedParams: { [key: string]: string } = {};

  for (const key in params) {
    cleanedParams[key] = String(params[key]);
  }

  Object.keys(cleanedParams).forEach((param) => {
    url = url.replace(`:${param}`, cleanedParams[param]);
  });

  if (searchParams) {
    url += "?";

    for (const [key, value] of Object.entries(searchParams)) {
      if (value !== undefined) {
        url += `${key}=${value}&`;
      }
    }

    url = url.replace(/&$/, "");
    url = url.replace(/\?$/, "");
  }

  return url;
};

export const API = env.VITE_API_URL;
