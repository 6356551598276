import { useEffect, useMemo, useRef, useState } from "react";

import { Flex, Text, Color, Input, Skeleton } from "src/elements";
import { PaginationArrow } from "./components/Arrow";
import { PaginationPage } from "./components/Page";
import styled from "styled-components";
import { textVariants } from "../Text/store/variants";
import { paginationSize } from "./store/constants";
import { between } from "src/utils/math";

export const PaginationElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .input-container {
    height: ${paginationSize};
    min-height: ${paginationSize};
    overflow: hidden;
  }

  input {
    padding: 0px;
    text-align: center;
    font-size: ${textVariants.small2.fontSize};
    color: ${Color.textSecondary};
    background-color: ${Color.lightGray};
    user-select: all;
  }
`;

interface Props {
  page: number;
  setPage: (page: number) => void;
  totalPages: number | undefined;
  isLoading?: boolean;
  isUsingInput?: boolean;
}

export const Pagination = ({
  page,
  setPage,
  totalPages: _totalPages,
  isLoading,
  isUsingInput,
}: Props) => {
  const [inputPage, setInputPage] = useState(String(page));
  const totalPages = Math.max(_totalPages ?? 1, 1);

  const inputPageTimeout = useRef<null | NodeJS.Timeout>(null);

  // Update page on input change.
  useEffect(() => {
    if (isUsingInput && !isLoading && (Number(inputPage) || 1 <= totalPages)) {
      if (inputPageTimeout.current) {
        clearTimeout(inputPageTimeout.current);
      }

      inputPageTimeout.current = setTimeout(() => {
        if (inputPage.length > 0) {
          setPage(between(1, Number(inputPage) || 1, totalPages));
        }
        if (Number(inputPage) > totalPages) {
          setInputPage(String(totalPages));
        }
      }, 400);
    }
  }, [inputPage]);

  // Update input and url query params on page change.
  useEffect(() => {
    setInputPage(String(page));
  }, [page]);

  const setCurrentPage = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setPage(page);
    }
  };

  const nextPage = () => {
    setCurrentPage(Math.min(page + 1, totalPages));
  };

  const previousPage = () => {
    setCurrentPage(Math.max(page - 1, 1));
  };

  const pagination = useMemo(() => {
    let pagination: { page: number; isJumper?: boolean }[] = [];

    // First page.
    if (totalPages > 0) {
      pagination.push({ page: 1 });
    }

    // Jump to left.
    if (page > 4) {
      pagination.push({ page: 1, isJumper: true });
    }

    // Current page.
    if (pagination.filter((p) => p.page === page).length === 0) {
      pagination.push({ page });
    }

    // Jump to right.
    if (totalPages - page > 3 && totalPages > 7) {
      pagination.push({ page: totalPages, isJumper: true });
    }

    // Last page.
    if (totalPages > 1 && page !== totalPages) {
      pagination.push({ page: totalPages });
    }

    // If possible, have at least 7 pages in pagination.
    for (let i = 1; i <= 4; i++) {
      const previous = page - i;
      const next = page + i;

      if (page <= totalPages - page) {
        // Generate from left first.
        if (previous > 1 && pagination.length < 7) {
          pagination.push({ page: previous });
        }

        if (next < totalPages && pagination.length < 7) {
          pagination.push({ page: next });
        }

        // Generate from right first.
      } else {
        if (next < totalPages && pagination.length < 7) {
          pagination.push({ page: next });
        }

        if (previous > 1 && pagination.length < 7) {
          pagination.push({ page: previous });
        }
      }
    }

    // Sort pagination by page numbers.
    pagination = pagination.sort((a, b) => (a.page < b.page ? -1 : 1));

    // Fix jumpers if necessary.
    if (pagination.length > 2 && pagination[1].isJumper) {
      pagination[1].page = pagination[2].page - 1;
    }
    if (pagination.length > 5 && pagination[5].isJumper) {
      pagination[5].page = pagination[4].page + 1;
    }

    return pagination;
  }, [page, totalPages]);

  if (isLoading) {
    return (
      <Flex justify="right" align="center" userSelect="none" gap="1px">
        <Skeleton width="200px" height="22px" />
      </Flex>
    );
  }

  return (
    <PaginationElement>
      <Flex align="center" gap="5px">
        {isUsingInput && (
          <>
            <Text variant="caption1" color={Color.textSecondary}>
              Page:
            </Text>

            <Input
              value={inputPage}
              setValue={setInputPage}
              width="24px"
              isSpellchecked={false}
            />

            <Text
              variant="small2"
              color={Color.textSecondary}
              whiteSpace="nowrap"
            >
              / {totalPages}
            </Text>
          </>
        )}
      </Flex>

      <Flex justify="right" align="center" userSelect="none" gap="1px">
        {/* Previous page */}
        <PaginationArrow
          side="left"
          totalPages={totalPages}
          isDisabled={page === 1}
          onClick={previousPage}
        />

        {/* Pages close to current one */}
        {pagination.map(({ page: p, isJumper }, i) => (
          <PaginationPage
            key={i}
            page={p}
            totalPages={totalPages}
            isJumper={isJumper}
            isActive={p === page}
            onClick={setCurrentPage}
          />
        ))}

        {/* Next page */}
        <PaginationArrow
          side="right"
          totalPages={totalPages}
          isDisabled={page === totalPages}
          onClick={nextPage}
        />
      </Flex>
    </PaginationElement>
  );
};
