import {
  OneStoreView,
  OneStoreViewStatus,
} from "@CommonTypes/merchflow/OneStoreView";
import { Dispatch, SetStateAction } from "react";
import { Metric } from "src/components";
import { Checkbox, Color, Flex, Icon, Link, Text } from "src/elements";
import { SubgridColumn } from "src/elements/SubgridTable/store/types";
import { OpenBasePlanogramReviewModal } from "src/modals/BasePlanogramReview/store/hooks";
import { OpenStorePlanogramReviewModal } from "src/modals/StorePlanogramReview/store/hooks";
import { pages } from "src/utils";
import { routeToUrl } from "src/utils/axios";
import { getCurrentUrl } from "src/utils/history";
import styled from "styled-components";

const ApprovedBubble = styled(Flex)`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${Color.primary};
`;

export const getStoresAllTableColumns = ({
  merchflowId,
  setStoresAllHistory,
  selection,
  setSelection,
  isAllSelected,
  setIsAllSelected,
  openBasePlanogramReviewModal,
  openStorePlanogramReviewModal,
}: {
  merchflowId: number;
  setStoresAllHistory: Dispatch<
    SetStateAction<{
      [merchflowId: number]: string;
    }>
  >;
  selection: number[];
  setSelection: (selection: number[]) => void;
  isAllSelected: boolean;
  setIsAllSelected: (isAllSelected: boolean) => void;
  openBasePlanogramReviewModal: (data: OpenBasePlanogramReviewModal) => void;
  openStorePlanogramReviewModal: (data: OpenStorePlanogramReviewModal) => void;
}) => {
  return [
    {
      id: "selection",
      header: "selection",
      justify: "center",
      headerRenderer: (
        <Checkbox
          width="min-content"
          isChecked={isAllSelected}
          setIsChecked={setIsAllSelected}
        />
      ),
      renderer: ({ row: { store_pog } }) => (
        <Checkbox
          width="min-content"
          isChecked={
            store_pog !== undefined
              ? isAllSelected
                ? selection.includes(store_pog.store_pog_id)
                  ? false
                  : true
                : selection.includes(store_pog.store_pog_id)
              : false
          }
          setIsChecked={() => {
            if (store_pog !== undefined) {
              if (selection.includes(store_pog.store_pog_id)) {
                setSelection(
                  selection.filter((s) => s !== store_pog.store_pog_id),
                );
              } else {
                setSelection([...selection, store_pog.store_pog_id]);
              }
            }
          }}
          isDisabled={store_pog === undefined}
        />
      ),
    },
    {
      id: "store",
      header: "Store Code",
      renderer: ({ value }) => (
        <Link
          to={`${routeToUrl(pages.merchflows_merchflowId_Stores_storeCode_, {
            merchflowId,
            storeCode: value,
          })}`}
          onClick={() => {
            setStoresAllHistory((history) => ({
              ...history,
              [merchflowId]: getCurrentUrl(),
            }));
          }}
        >
          <Text variant="small2">{value}</Text>
        </Link>
      ),
    },
    {
      id: "status",
      header: "Store Status",
      justify: "center",
      renderer: ({ value }) => (
        <>
          {value === OneStoreViewStatus.GENERATING_STORE_POG && (
            <Icon name="spinner" size={14} />
          )}

          {value === OneStoreViewStatus.ONE_STORE_POG_APPROVED && (
            <Icon name="checkmarkCircle" size={14} />
          )}

          {value === OneStoreViewStatus.MULTIPLE_STORE_POGS_APPROVED && (
            <Icon name="alert" color={Color.red} size={14} />
          )}
        </>
      ),
    },
    {
      id: "template",
      header: "Template",
      subColumns: [
        {
          id: "templateId",
          header: "ID",
          justify: "center",
          renderer: ({ row }) =>
            row.base_pog && (
              <Flex
                width="100%"
                justify="center"
                onClick={() => {
                  if (row.base_pog) {
                    openBasePlanogramReviewModal({
                      merchflowId,
                      basePogId: row.base_pog.base_pog_id,
                      basePog: row.base_pog.base_pog_data,
                    });
                  }
                }}
              >
                <Text variant="small2">{row.base_pog.base_pog_id}</Text>
              </Flex>
            ),
        },
      ],
    },
    {
      id: "planogram",
      header: "Latest Planogram",
      subColumns: [
        {
          id: "planogramId",
          header: "ID",
          justify: "center",
          renderer: ({ row }) =>
            row.store_pog && (
              <Flex
                width="100%"
                justify="center"
                onClick={() => {
                  openStorePlanogramReviewModal({
                    merchflowId,
                    storePlanograms: [
                      {
                        basePog: row.base_pog
                          ? {
                              basePogId: row.base_pog?.base_pog_id,
                              basePogData: row.base_pog?.base_pog_data,
                            }
                          : undefined,
                        storePog: row.store_pog
                          ? {
                              storePogId: row.store_pog.store_pog_id,
                              storePogData: row.store_pog.store_pog_data,
                              isApproved: false,
                            }
                          : undefined,
                        templateId: row.template_id,
                      },
                    ],
                  });
                }}
              >
                <Text variant="small2">{row.store_pog.store_pog_id}</Text>
              </Flex>
            ),
        },
        {
          id: "planogramApproved",
          header: "Approved",
          justify: "center",
          renderer: ({ row }) => (
            <>
              {row.status === OneStoreViewStatus.ONE_STORE_POG_APPROVED && (
                <Flex height="min-content" minHeight="14px" align="center">
                  <ApprovedBubble />
                </Flex>
              )}
            </>
          ),
        },
      ],
    },
    {
      id: "returnOnSpace",
      header: "Return on Space",
      width: "120px",
      justify: "center",
      renderer: ({ row }) =>
        row.store_pog && (
          <Metric
            percent={row.store_pog?.store_pog_data.metrics?.returnOnSpace}
            isInline
          />
        ),
    },
    {
      id: "coreRange",
      header: "Core Range",
      width: "120px",
      justify: "center",
      renderer: ({ row }) =>
        row.store_pog && (
          <Metric
            percent={row.store_pog?.store_pog_data.metrics?.coreRange}
            isInline
          />
        ),
    },
    {
      id: "shelfAlignment",
      header: "Shelf Alignment",
      width: "120px",
      justify: "center",
      renderer: ({ row }) =>
        row.store_pog && (
          <Metric
            percent={row.store_pog?.store_pog_data.metrics?.shelfAlignment}
            isInline
          />
        ),
    },
    {
      id: "dosMos",
      header: "DOS/MOS",
      width: "120px",
      justify: "center",
      renderer: ({ row }) =>
        row.store_pog && (
          <Metric
            percent={row.store_pog?.store_pog_data.metrics?.dosMos}
            isInline
          />
        ),
    },
    {
      id: "size",
      header: "Size",
      justify: "center",
      renderer: ({ row }) =>
        row.store_pog?.store_pog_data.planogram.bays.length,
    },
  ] satisfies SubgridColumn<OneStoreView>[];
};
