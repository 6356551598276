import { PlanogramItem } from "@CommonTypes/merchflow/pog/pog";
import {
  getPlanogramItemMOSPercentage,
  getPlanogramItemDaysOfSupplyPercentage,
  getPlanogramItemCapacity,
} from "@CommonUtils/pog/item";
import { useMemo } from "react";
import { Tags } from "src/elements";

interface Props {
  item: PlanogramItem;
}

export const ItemDetails = ({ item }: Props) => {
  const [dos, mos, capacity] = useMemo(() => {
    let dos = 0;
    let mos = 0;
    let capacity = 0;

    if (item && item.shelf) {
      for (const bay of item.shelf.bay.planogram.bays) {
        for (const shelf of bay.shelves) {
          for (const _item of shelf.items) {
            if (_item.productCode === item.productCode) {
              mos += getPlanogramItemMOSPercentage(_item);
              dos += getPlanogramItemDaysOfSupplyPercentage(_item);
              capacity += getPlanogramItemCapacity(_item);
            }
          }
        }
      }
    }

    return [Math.floor(dos), Math.floor(mos), Math.floor(capacity)];
  }, [item]);

  return (
    <Tags
      tags={[
        { label: "Item ID", value: `${item.productCode} - ${item.name}` },
        ...(item.shelf ? [{ label: "MOS", value: `${mos}%` }] : []),
        ...(item.shelf ? [{ label: "DOS", value: `${dos}%` }] : []),
        { label: "Movement", value: item.quantity.toFixed(2) },
        { label: "Sales", value: item.salesAmount.toFixed(2) },
        ...(item.profit !== null
          ? [{ label: "Profit", value: item.profit }]
          : []),
        ...(item.shelf ? [{ label: "Capacity", value: capacity }] : []),
      ]}
    />
  );
};
