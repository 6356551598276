import { useAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { useModals } from "src/components/Modals";
import { PlanogramHovererDetails } from "src/components/PlanogramExtensions/PlanogramHovererDetails/PlanogramHovererDetails";
import { Button, Flex, Modal } from "src/elements";
import { Metrics } from "src/pages/MerchflowTemplates/components/Metrics";
import { basePlanogramReviewAtom } from "./store/atoms";
import { OpenBasePlanogramReviewModal } from "./store/hooks";
import { Modals } from "..";
import { omniAtom } from "src/utils/atoms";
import { adapterPogNonCircularToPog } from "@CommonAdapters/pog";
import { emptyContainersAtom } from "src/pages/merchandise/store/atoms";
import { Planogram } from "src/components";
import { useApi } from "src/api";
import { useInlineLoaders } from "src/components/InlineLoader";
import { RoutePutBasePogSelectedStatus } from "@CommonApi/pogger/base-pog/status";
import { useNavigation } from "src/utils/navigation";
import { pages } from "src/utils";

export const BasePlanogramReviewModal = () => {
  const [data, setData] = useAtom(basePlanogramReviewAtom);

  useEffect(() => {
    return () => {
      setData(null);
    };
  }, []);

  return data ? <BasePlanogramReviewModalInner {...data} /> : null;
};

const BasePlanogramReviewModalInner = ({
  merchflowId,
  basePogId,
  basePog,
  isFavorite: _isFavorite,
}: OpenBasePlanogramReviewModal) => {
  const modalName: Modals = "BasePlanogramReviewModal";

  const { putBasePogStatusApi } = useApi();
  const { closeModal: _closeModal } = useModals();
  const { isInlineLoading } = useInlineLoaders();
  const { navigate } = useNavigation();

  const [isFavorite, setIsFavorite] = useState(_isFavorite);
  const pogAtom = useMemo(
    () =>
      omniAtom({
        prefix: "modals/basePlanogramReview",
        key: "pog",
        value: adapterPogNonCircularToPog(basePog),
      }),
    [basePog],
  );

  const closeModal = () => {
    _closeModal(modalName);
  };

  const toggleFavorite = async () => {
    if (isFavorite === undefined) return;

    await putBasePogStatusApi(
      isFavorite
        ? { is_not_favourite: [basePogId] }
        : { is_favourite: [basePogId] },
    );

    setIsFavorite(!isFavorite);
  };

  const navigateToBasePogPage = () => {
    navigate(pages.merchflows_merchflowId_BasePog_basePogId_, {
      merchflowId,
      basePogId,
    });
    closeModal();
  };

  return (
    <Modal
      name={modalName}
      title={`Base Planogram ID: ${basePogId}`}
      width="90vw"
      height="95vh"
    >
      <Flex column padding="10px" gap="10px" grow>
        <Flex width="100%" grow gap="10px">
          <Planogram
            pogAtom={pogAtom}
            containersAtom={emptyContainersAtom}
            isPog
            isEditable
          />

          <Flex width="210px" column justify="between" height="100%" gap="10px">
            <Flex width="100%">
              <Button
                width="100%"
                variant="inverted"
                isLoading={isInlineLoading(RoutePutBasePogSelectedStatus)}
                dropdown={[
                  {
                    label: isFavorite ? "Unfavorite" : "Favorite",
                    isDisabled: isFavorite === undefined,
                    onClick: toggleFavorite,
                  },
                  {
                    label: "Edit",
                    onClick: navigateToBasePogPage,
                  },
                ]}
              >
                TEMPLATE
              </Button>
            </Flex>

            <Metrics metrics={basePog?.metrics} />
          </Flex>
        </Flex>

        <PlanogramHovererDetails />
      </Flex>
    </Modal>
  );
};
