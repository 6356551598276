import { adapterPogNonCircularToPog } from "@CommonAdapters/pog";
import { AllBasePogResponse } from "@CommonApi/merchandise";
import { useAtomValue, useSetAtom } from "jotai";
import { useInlineLoaders } from "src/components/InlineLoader";
import { useToasts } from "src/components/Toasts";
import { filtersAppliedAtom } from "src/modals/NewFlowReview/store/atoms";
import { filterConfigBasePogsAtom } from "src/pages/merchandise/store/atoms";
import {
  getAllBasePogOfTemplate,
  getAllBasePogVersion,
} from "src/pages/merchandise/store/requests";
import {
  basePogTemplateAtoms,
  pogTemplateAtoms,
  selectedTemplateAtom,
} from "src/pages/MerchflowTemplateBasePogs/store/atom";
import { BasePogWithId } from "src/pages/MerchflowTemplateBasePogs/store/types";
import { omniAtom } from "src/utils/atoms";

export const useBasePogApi = () => {
  const { addInlineLoader, removeInlineLoader } = useInlineLoaders();
  const filtersApplied = useAtomValue(filtersAppliedAtom);
  const setAllBasePog = useSetAtom(filterConfigBasePogsAtom);
  const setBasePogTemplateAtoms = useSetAtom(basePogTemplateAtoms);
  const setPogTemplateAtoms = useSetAtom(pogTemplateAtoms);
  const setSelectedTemplate = useSetAtom(selectedTemplateAtom);
  const { toast } = useToasts();

  const loadAllBasePog = async (inputFilterId?: number) => {
    const id = inputFilterId ? inputFilterId : filtersApplied?.id || 0;

    let result: AllBasePogResponse[] = [];
    if (id === 0) {
      setAllBasePog([]);
      return [];
    }

    try {
      addInlineLoader("loadAllBasePogVersion");
      result = await getAllBasePogVersion(id);
    } catch (error) {
      if (error instanceof Error) {
        toast({ title: error.message, error });
      } else {
        toast({ title: JSON.stringify(error), error });
      }
      result = [];
    }
    removeInlineLoader("loadAllBasePogVersion");

    setAllBasePog(result);
    return result;
  };

  const loadAllBasePogOfTemplate = async (
    templateId: string,
    merchflowId: number,
  ) => {
    addInlineLoader("loadAllBasePogOfTemplate");
    let result: BasePogWithId[] = [];

    try {
      result = await getAllBasePogOfTemplate({
        merchflow_id: merchflowId,
        template_id: templateId,
      });
    } catch (error) {
      toast({
        title: "Failed to load template base pogs",
        error,
      });
    }

    setSelectedTemplate(templateId);
    setBasePogTemplateAtoms(result);
    setPogTemplateAtoms(
      result.map((d) =>
        omniAtom({
          key: `basePogTemplate-${d.id}`,
          value: adapterPogNonCircularToPog(d.pog_data),
        }),
      ),
    );
    removeInlineLoader("loadAllBasePogOfTemplate");
  };

  return {
    loadAllBasePog,
    loadAllBasePogOfTemplate,
  };
};
