import { useSetAtom } from "jotai";
import { useModals } from "src/components/Modals";
import { storePlanogramReviewAtom } from "./atoms";
import { StoreVersionsView } from "@CommonApi/merchflows/_merchflowId_/stores/_storeCode_";

export interface OpenStorePlanogramReviewModal {
  merchflowId: number;
  storePlanograms: StoreVersionsView[];
  planogramIndex?: number;
}

export const useStorePlanogramReviewModal = () => {
  const { openModal } = useModals();
  const setData = useSetAtom(storePlanogramReviewAtom);

  const openStorePlanogramReviewModal = (
    data: OpenStorePlanogramReviewModal,
  ) => {
    setData(data);
    openModal("StorePlanogramReviewModal");
  };

  return { openStorePlanogramReviewModal };
};
