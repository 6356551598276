import { adapterPogNonCircularToPog } from "@CommonAdapters/pog";
import {
  OneStoreViewStatus,
  OneStoreViewThumbnail,
} from "@CommonTypes/merchflow/OneStoreView";
import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";
import { PogNonCircular } from "@CommonTypes/merchflow/pog/pog";
import hexToRgba from "hex-to-rgba";
import { atom } from "jotai";
import { useMemo, useRef } from "react";
import { Planogram, Tooltip } from "src/components";
import {
  Icon,
  Color,
  Flex,
  ProgressBar,
  VerticalDivider,
  Text,
  Selection,
  Spinner,
} from "src/elements";
import { emptyContainersAtom } from "src/pages/merchandise/store/atoms";
import { useHoverClickable } from "src/utils/hoverClickable";
import styled from "styled-components";

export const pogOverviewWidth = "422px";
export const pogOverviewHeight = "222px";
const sidebarWidth = "44px";

export enum OneTemplateViewStatus {
  MULTIPLE_BASE_POG_FAVOURITE = "MULTIPLE_BASE_POG_FAVOURITE",
}

type PogOverviewStatusEnum = OneStoreViewStatus | OneTemplateViewStatus;

const isWarningStatuses = (status: PogOverviewStatusEnum) =>
  OneStoreViewStatus.MULTIPLE_STORE_POGS_APPROVED === status ||
  OneTemplateViewStatus.MULTIPLE_BASE_POG_FAVOURITE === status;

const PogOverviewElement = styled(Flex)<{ width?: string; height?: string }>`
  position: relative;
  width: ${({ width }) => width || pogOverviewWidth};
  height: ${({ height }) => height || pogOverviewHeight};
  border-radius: 3px;
`;

const Badge = styled(Flex)<{ status: PogOverviewStatusEnum }>`
  position: absolute;
  top: -10px;
  left: -10px;
  width: 24px;
  height: 24px;
  background-color: ${({ status }) =>
    (status === OneTemplateViewStatus.MULTIPLE_BASE_POG_FAVOURITE &&
      Color.yellowActive) ||
    (status === OneStoreViewStatus.MULTIPLE_STORE_POGS_APPROVED &&
      Color.redActive) ||
    (status === OneStoreViewStatus.ONE_STORE_POG_APPROVED &&
      Color.greenSmoke) ||
    Color.white};
  border: 1px solid
    ${({ status }) =>
      (status === OneTemplateViewStatus.MULTIPLE_BASE_POG_FAVOURITE &&
        Color.yellowActive) ||
      (status === OneStoreViewStatus.MULTIPLE_STORE_POGS_APPROVED &&
        Color.red) ||
      (status === OneStoreViewStatus.ONE_STORE_POG_APPROVED &&
        Color.primaryActive) ||
      Color.spaceGray};
  color: ${({ status }) =>
    (isWarningStatuses(status) && Color.white) ||
    (status === OneStoreViewStatus.ONE_STORE_POG_APPROVED && Color.primary) ||
    Color.textSecondary};
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const StatusOverlay = styled(Flex)<{ status: PogOverviewStatusEnum }>`
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  border-color: ${({ status }) =>
    (status === OneTemplateViewStatus.MULTIPLE_BASE_POG_FAVOURITE &&
      Color.yellow) ||
    (status === OneStoreViewStatus.MULTIPLE_STORE_POGS_APPROVED && Color.red) ||
    (status === OneStoreViewStatus.ONE_STORE_POG_APPROVED && Color.primary) ||
    (status === OneStoreViewStatus.NO_STORE_POGS_APPROVED && Color.textMain) ||
    Color.lightGrayHover};
  background-color: ${({ status }) =>
    (status === OneTemplateViewStatus.MULTIPLE_BASE_POG_FAVOURITE &&
      hexToRgba(Color.yellow, 0.2)) ||
    (status === OneStoreViewStatus.MULTIPLE_STORE_POGS_APPROVED &&
      hexToRgba(Color.red, 0.2)) ||
    (status === OneStoreViewStatus.ONE_STORE_POG_APPROVED &&
      hexToRgba(Color.primary, 0.2)) ||
    "transparent"};
  pointer-events: none;
`;

const Label = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
  border-radius: 0 0 3px 0;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(1px);
`;

const Thumbnail = styled(Flex)`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: bottom left;
`;

interface Props<T> {
  selectionId?: T;
  pog?: PogNonCircular | false;
  containers?: PlanogramContainer[] | false;
  status?: PogOverviewStatusEnum;
  thumbnail?: OneStoreViewThumbnail;
  label?: string;
  badge?: string | number | false;
  warning?: string | false;
  isAllSelected?: boolean;
  selection?: T[];
  setSelection?: (selection: T[]) => void;
  width?: string;
  height?: string;
  loadingMessage: string;
  isShowMetrics?: boolean;
  isRenderingAsImage?: boolean;
}

export const PogOverview = <T extends number | string>({
  selectionId,
  pog,
  containers,
  status = OneStoreViewStatus.NONE,
  thumbnail,
  label,
  badge,
  warning,
  isAllSelected,
  selection,
  setSelection,
  width,
  height,
  loadingMessage,
  isShowMetrics = true,
  isRenderingAsImage = true,
}: Props<T>) => {
  const refOverview = useRef<HTMLDivElement>(null);
  const { isHovered } = useHoverClickable(refOverview);

  const pogAtom = useMemo(
    () => (pog ? atom(adapterPogNonCircularToPog(pog)) : null),
    [pog, status],
  );

  const containersAtom = useMemo(
    () => (containers ? atom(containers) : null),
    [containers],
  );

  return (
    <PogOverviewElement width={width} height={height} ref={refOverview}>
      {badge && (
        <Badge status={status}>
          <Text variant="caption1" color="inherit" bold>
            {badge}
          </Text>
        </Badge>
      )}

      <Flex background={Color.planogram} grow>
        {pogAtom &&
          (thumbnail === undefined || thumbnail.status === "ERROR") && (
            <Planogram
              key={pogAtom.toString()}
              pogAtom={pogAtom}
              containersAtom={containersAtom || emptyContainersAtom}
              isPog={!containers}
              isRenderingAsImage={isRenderingAsImage}
            />
          )}

        {thumbnail?.status === "DONE" && (
          <Thumbnail background={`url(${thumbnail.url})`} />
        )}

        {((status === OneStoreViewStatus.GENERATING_STORE_POG && !pog) ||
          thumbnail?.status === "IN_PROGRESS") && (
          <Spinner size="big" label={loadingMessage} isCentered />
        )}
      </Flex>

      <VerticalDivider color={Color.lightGrayHover} />

      {isShowMetrics && (
        <Flex
          width={sidebarWidth}
          column
          gap="5px"
          align="center"
          padding="5px"
        >
          <ProgressBar
            percent={pog ? pog.metrics?.returnOnSpace : undefined}
            height="7px"
          />

          <ProgressBar
            percent={pog ? pog.metrics?.coreRange : undefined}
            height="7px"
          />

          <ProgressBar
            percent={pog ? pog.metrics?.shelfAlignment : undefined}
            height="7px"
          />

          <ProgressBar
            percent={pog ? pog.metrics?.dosMos : undefined}
            height="7px"
          />
        </Flex>
      )}

      <StatusOverlay status={status}>
        {isWarningStatuses(status) && (
          <Icon
            name="alert"
            color={
              status === OneStoreViewStatus.MULTIPLE_STORE_POGS_APPROVED
                ? Color.red
                : Color.yellow
            }
            size={32}
          />
        )}
      </StatusOverlay>

      {label && isHovered && (
        <Label>
          <Text variant="small1" color={Color.white}>
            {label}
          </Text>
        </Label>
      )}

      {warning && (
        <Flex
          position="absolute"
          width={sidebarWidth}
          bottom="0"
          right="0"
          padding="10px"
          justify="center"
        >
          <Icon name="alert" color={Color.yellow} size={24} />
          <Tooltip>{warning}</Tooltip>
        </Flex>
      )}

      {selectionId !== undefined &&
        selection !== undefined &&
        setSelection !== undefined && (
          <Selection
            id={selectionId}
            isAllSelected={isAllSelected || false}
            selection={selection}
            setSelection={setSelection}
          />
        )}
    </PogOverviewElement>
  );
};
